import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { QRCodeCanvas } from "qrcode.react";

// component
import ModalApp from "views/components/ModalNormal";
import { Grid } from '@mui/material';

//  Redux
import { setDataPopupContact } from "@core/redux/actions/app/popup";

const PopupContact = ({ popupContactShow }) => {
    const [urlLineContact, setUrlLineContact] = useState(null);
    const dataSetting = useSelector(state => state.reduxAppDataSetting.result);
    const dispatch = useDispatch();

    const onShow = () => {
        dispatch(setDataPopupContact(false));
    };

    useEffect(() => {
        if (dataSetting?.agent_contact) {
            const dataFilter = dataSetting.agent_contact.filter((item) => item?.contact_channel === 0);

            if (dataFilter.length > 0) {
                setUrlLineContact(dataFilter[0]?.channel_link);
            }
        }
    }, [dataSetting]);

    return (popupContactShow && urlLineContact) && (
        <a href={urlLineContact} target='_blank' onClick={onShow}>
            <ModalApp show={popupContactShow} onShow={onShow}>
                <div className="popupLineTitle center">
                    แอดไลน์
                </div>

                <div className="popupLineDetail center">
                    เเอดไลน์สำหรับไปยังทางเข้าเล่น
                    หรือติดต่อเเจ้งปัญหาต่าง ๆ ตลอด 24 ชม.
                </div>

                <div className="popupLineQRCode center">
                    <div className="popupLineQRCodeBG">
                        <QRCodeCanvas value={urlLineContact} size={210} />
                    </div>

                    <div className="popupLineIconPosition">
                        <img className="popupLineIcon" src="/images/icon/line.png" alt="" />
                    </div>
                </div>

                <div className="popupLineQRCode center">
                    <button className="btn btnSuccess btnFilter btnHover btnWidthFull btnRadiusMedium textBoldHight">
                        <Grid container alignItems="center" justifyContent="center" spacing={2}>
                            <Grid item>
                                <img className="popupLineIconBtn" src="/images/icon/line_white.webp" alt="" />
                            </Grid>
                            <Grid item>
                                แอดไลน์
                            </Grid>
                        </Grid>
                    </button>
                </div>

            <br />
            </ModalApp>
        </a>
    );
}

export default PopupContact;