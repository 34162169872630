const apiDomain = (val) => {
    const splitDomain = val.split(".");
    const domainLength = splitDomain.length;
    const baseDomain =
        domainLength === 1 ? splitDomain[0] :
        domainLength === 2 ? splitDomain.join(".") :
        splitDomain.slice(-3).join("."); // ใช้ 3 ส่วนสุดท้ายแทน 2 ส่วน

    return `api.${baseDomain}/api/v1`;
};

export default apiDomain;